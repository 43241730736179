import './bootstrap.front.js';
import NavSidebar from '@/Front/Layouts/NavSidebar.vue';
import CalendarWidget from '@/Front/Views/Common/Header/CalendarWidget.vue';
import CartWidget from '@/Front/Views/Common/Header/CartWidget.vue';
import LocaleWidget from '@/Front/Views/Common/Header/LocaleWidget.vue';
import LocationWidget from '@/Front/Views/Common/Header/LocationWidget.vue';
import SubscribeWidget from '@/Front/Views/Common/SubscribeWidget.vue';
import FeedbackForm from '@/Front/Views/Contacts/FeedbackForm.vue';
import RequestForm from '@/Front/Views/Events/RequestForm.vue';
import TicketsWidget from '@/Front/Views/Events/TicketsWidget.vue';
import KassirRu from '@/Front/Views/Events/Widgets/KassirRu.vue';
import ReferralLink from '@/Front/Views/Events/Widgets/ReferralLink.vue';
import TicketPro from '@/Front/Views/Events/Widgets/TicketPro.vue';
import Slides from '@/Front/Views/Mainpage/Slides.vue';
import OrderForm from '@/Front/Views/Orders/OrderForm.vue';
import SearchEvents from '@/Front/Views/Search/SearchEvents.vue';
import Orders from '@/Front/Views/Users/Orders.vue';
import Profile from '@/Front/Views/Users/Profile.vue';
import ProfileNavigation from '@/Front/Views/Users/ProfileNavigation.vue';
import ReturnRequestForm from '@/Front/Views/Users/ReturnRequestForm.vue';
import Alpine from 'alpinejs';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import { createApp } from 'vue';
import { ZiggyVue } from 'ziggy-js';
import Theme from './Front/Theme/index';
import 'tippy.js/dist/tippy.css';

const pinia = createPinia();

Alpine.start();

const mounts = {
  NavSidebar: '#v-sidebar',
  TicketsWidget: '#v-tickets-widget',
  SearchEvents: '#v-search-events',
  Slides: '#vslider',
  Profile: '#v-profile',
  ProfileNavigation: '#v-profile-navigation',
  KassirRu: '#v-ext-widget-kassir-ru',
  ReferralLink: '#v-ext-widget-referral-link',
  TicketPro: '#v-ext-widget-ticketpro',
  OrderForm: '#v-order-form',
  SubscribeWidget: '#v-subscribe-widget',
  FeedbackForm: '#v-feedback-form',
  RequestForm: '#v-event-request-form',
  CartWidget: '#v-cart-widget',
  ReturnRequestForm: '#v-order-return-request',
  CalendarWidget: '#v-calendar-widget',
  LocationWidget: '#v-location-widget',
  LocaleWidget: '#v-locale-widget',
  Orders: '#v-profile-orders',
};

const apps = [];
[
  NavSidebar,
  TicketsWidget,
  SearchEvents,
  Slides,
  Profile,
  ProfileNavigation,
  KassirRu,
  ReferralLink,
  TicketPro,
  OrderForm,
  SubscribeWidget,
  FeedbackForm,
  RequestForm,
  CartWidget,
  ReturnRequestForm,
  CalendarWidget,
  LocationWidget,
  LocaleWidget,
  Orders,
].forEach((component) => {
  const selector = mounts[component.__name];
  if (document.querySelector(selector) === null) return;

  const app = createApp(component);
  apps.push(app);

  app
    .use(ZiggyVue)
    .use(PrimeVue, {
      ripple: true,
      unstyled: true,
      pt: Theme,
    })
    .use(ConfirmationService)
    .use(ToastService)
    .use(DialogService)
    .use(pinia)
    .directive('ripple', Ripple);
});

apps.forEach((app) => {
  app.mount(mounts[app._component.__name]);
});
