<template>
  <SecondaryButton
    type="link"
    :href="route('front.login.social', { provider: 'apple' })"
  >
    <AppleLoginLogo />
    {{ i18n.trans('Войти через Apple ID') }}
  </SecondaryButton>

  <SecondaryButton
    type="link"
    :href="route('front.login.social', { provider: 'google' })"
  >
    <GoogleLoginLogo />
    {{ i18n.trans('Войти через Google') }}
  </SecondaryButton>
</template>

<script setup>
import SecondaryButton from '@/Front/Components/SecondaryButton.vue';
import useTranslations from '@/Front/Composables/useTranslations.js';
import AppleLoginLogo from 'images/front/icons/apple_login_logo.svg';
import GoogleLoginLogo from 'images/front/icons/google_login_logo.svg';

const i18n = useTranslations();
</script>
