<template>
  <form
    class="md:mx-18 md:gap-x-30 mx-6 grid grid-cols-1 md:grid-cols-5"
    @submit.prevent="createOrder"
  >
    <div class="space-y-6 md:col-span-3">
      <div class="flex items-start">
        <div class="grow">
          <h3>
            {{ appStore.timetable.eventName }},
            <br />
            {{ appStore.timetable.eventCityName }}, {{ appStore.timetable.starts_at }}
          </h3>
        </div>
        <div class="border-primary-800 text-primary-500 hidden items-center gap-1 rounded border p-2 text-sm">
          <TimerSvg />
          <span>18:00</span>
        </div>
      </div>
      <div class="flex flex-col">
        <div
          v-if="appStore.timetable.require_full_name || appStore.timetable.required_document_number"
          class="bg-primary-50 mb-4 flex flex-col gap-2 rounded-lg p-4"
        >
          <h3 class="text-lg">
            {{ i18n.trans('Внимание!') }}
          </h3>
          <p>
            {{ i18n.trans('На данное мероприятие действуют особые условия безопасности. Необходимо ввести данные для каждого билета') }}
          </p>
          <p>
            <strong>{{ i18n.trans('При посещении мероприятие при себе иметь документ') }}</strong>
          </p>
        </div>
        <TicketsList
          :errors="form.errors"
          :tickets-data="form.ticketsData"
          @set-ticket-full-name="setTicketFullName"
          @set-ticket-document-number="setTicketDocumentNumber"
        />
      </div>
      <div class="space-y-4">
        <h4 class="text-lg md:text-xl">
          {{ i18n.trans('Ваши данные') }}
        </h4>
        <FormInput :error="form.errors.full_name">
          <input
            v-model="form.full_name"
            type="text"
            :placeholder="i18n.trans('Имя и Фамилия')"
          />
        </FormInput>

        <FormInput :error="form.errors.phone">
          <InputPhone v-model="form.phone" />
        </FormInput>
        <FormInput :error="form.errors.email">
          <input
            v-model="form.email"
            type="text"
            placeholder="E-mail"
          />
        </FormInput>
        <FormInput :error="form.errors.payment_type">
          <Select
            v-model="paymentType"
            :options="paymentTypes"
            option-label="label"
            option-value="value"
            :placeholder="i18n.trans('Способ оплаты')"
            append-to="self"
            @change="selectPaymentType"
          />
        </FormInput>
        <div
          v-if="form.payment_type === cloudPaymentsId"
          class="flex items-center gap-1 text-sm"
        >
          <Checkbox
            v-model="form.save_card"
            :binary="true"
            input-id="saveCard"
          />
          <label
            class="grow"
            for="saveCard"
          >
            {{ i18n.trans('Сохранить этот способ оплаты') }}
          </label>
        </div>
      </div>
      <hr />
      <div class="space-y-4">
        <h4 class="text-lg md:text-xl">
          {{ i18n.trans('Способ получения') }}
        </h4>
        <FormInput :error="form.errors.shipping_type">
          <Select
            v-model="form.shipping_type"
            :options="shippingTypes"
            option-label="label"
            option-value="value"
            append-to="self"
            :placeholder="i18n.trans('Способ получения')"
          />
        </FormInput>
      </div>
    </div>
    <div class="flex flex-col gap-6 pt-6 md:col-span-2 md:self-start md:border md:border-neutral-300 md:p-12">
      <hr class="md:hidden" />
      <div class="flex flex-col gap-4">
        <PromoCode
          :code="cartStore.promocode"
          @activate="addPromocode"
        />
      </div>
      <hr />
      <div class="space-y-4">
        <Certificate
          :user="userStore.user"
          :certificate="cartStore.certificate"
          @activate="addCertificate"
        />
      </div>
      <template v-if="userStore.user">
        <hr />
        <div class="flex items-center space-y-4">
          <div>
            <h4 class="md:text-lg">
              {{ i18n.trans('Оплатить бонусами') }}
            </h4>
            <div>{{ userStore.user.balance_int }} {{ i18n.trans('Бонусов') }}</div>
          </div>
          <ToggleSwitch
            v-model="form.use_bonus"
            class="ml-auto"
            :disabled="bonusSwitchDisabled"
            @update:model-value="toggleBonuses"
          />
        </div>
      </template>
      <hr class="border-dashed" />
      <div class="space-y-2">
        <div class="flex">
          <div class="grow">
            {{ i18n.trans('Сервисный сбор') }}
          </div>
          <div>
            {{ cartStore.serviceTaxFee }}
            <span class="font-tenge">a</span>
          </div>
        </div>
        <div
          v-if="cartStore.bankFee > 0"
          class="flex"
        >
          <div class="grow">
            {{ i18n.trans('Комиссия за платеж') }}
          </div>
          <div>
            {{ cartStore.bankFee }}
            <span class="font-tenge">a</span>
          </div>
        </div>
        <div class="flex">
          <div class="grow">
            {{ i18n.trans('Скидка') }}
          </div>
          <div>
            {{ cartStore.discount }}
            <span class="font-tenge">a</span>
          </div>
        </div>
        <div
          v-if="cartStore.cartBonus < 0"
          class="flex"
        >
          <div class="grow">
            {{ i18n.trans('Бонусы') }}
          </div>
          <div>
            {{ cartStore.cartBonus }}
            <span class="font-tenge">a</span>
          </div>
        </div>
        <div class="flex">
          <div class="grow text-lg font-bold">
            {{ i18n.trans('Итого') }}
          </div>
          <div class="text-lg font-bold">
            {{ cartStore.total }}
            <span class="font-tenge">a</span>
          </div>
        </div>
      </div>
      <hr />
      <div class="space-y-4">
        <div class="flex items-center gap-1 text-sm leading-tight">
          <Checkbox
            v-model="form.agreement"
            :binary="true"
            input-id="formAgreement"
          />
          <label
            class="grow"
            :class="{ 'text-red-600': form.errors.agreement }"
            for="formAgreement"
            v-html="agreementLabelText"
          />
        </div>
        <div
          v-if="appStore.timetable.event.condition_checkbox"
          class="flex items-center gap-1 text-sm leading-tight"
        >
          <Checkbox
            v-model="form.additionalCondition"
            :binary="true"
            input-id="formAdditionalCondition"
          />
          <label
            class="grow"
            :class="{ 'text-red-600': form.errors.additionalCondition }"
            for="formAdditionalCondition"
            v-html="additionalCheckboxText"
          />
        </div>
        <div
          v-if="appStore.timetable.event.is_send_tickets_by_sms"
          class="flex items-center gap-1 text-sm leading-tight"
        >
          <Checkbox
            v-model="form.sendTicketsBySms"
            :binary="true"
            input-id="formSendTicketsBySms"
          />
          <label
            class="grow"
            :class="{ 'text-red-600': form.errors.sendTicketsBySms }"
            for="formSendTicketsBySms"
          >
            {{ i18n.trans('Я согласен на сбор и обработку данных для отправки смс') }}
            <span class="text-danger">*</span>
          </label>
        </div>

        <div class="flex items-center gap-1 text-sm leading-tight">
          <Checkbox
            v-model="form.subscribe"
            :binary="true"
            input-id="formSubscribe"
          />
          <label
            class="grow"
            :class="{ 'text-red-600': form.errors.subscribe }"
            for="formSubscribe"
          >
            {{ i18n.trans('Я хочу получать анонсы мероприятий') }}
          </label>
        </div>
      </div>
      <PrimaryButton
        type="submit"
        class="w-full"
        :disabled="isSubmitDisabled"
      >
        {{ i18n.trans('Оплатить') }}
      </PrimaryButton>
    </div>
  </form>
</template>

<script setup>
import FormInput from '@/Front/Components/FormInput.vue';
import InputPhone from '@/Front/Components/InputPhone.vue';
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp.js';
import { useCart } from '@/Front/Stores/useCart.js';
import { useUser } from '@/Front/Stores/useUser.js';
import Certificate from '@/Front/Views/Orders/Certificate.vue';
import PromoCode from '@/Front/Views/Orders/PromoCode.vue';
import TicketsList from '@/Front/Views/Orders/TicketsList.vue';
import TimerSvg from 'images/front/icons/timer.svg';
import Checkbox from 'primevue/checkbox';
import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';
import { computed, ref, watch } from 'vue';

const appStore = useApp();
const cartStore = useCart();
const userStore = useUser();
const i18n = useTranslations();

const paymentTypes = window.$app.paymentTypes;
const shippingTypes = window.$app.shippingTypes;
const cloudPaymentsId = window.$app.cloudPaymentsId;
const agreementLabelText = `${i18n.trans('Ознакомлен и согласен с договором оферты')}<span class="text-danger">*</span>`;
const additionalCheckboxText = `${i18n.trans(appStore.timetable.event.condition_checkbox_text)}<span class="text-danger">*</span>`;

const form = useForm({
  full_name: userStore.user?.full_name,
  phone: userStore.user?.phone ? `+${userStore.user.phone}` : null,
  email: userStore.user?.email,
  shipping_type: shippingTypes?.length === 1 ? shippingTypes[0].value : null,
  payment_type: paymentTypes?.length === 1 ? paymentTypes[0].value : null,
  save_card: false,
  agreement: false,
  sendTicketsBySms: false,
  additionalCondition: false,
  subscribe: false,
  use_bonus: cartStore.bonus < 0,
  ticketsData: {},
});

watch(
  () => cartStore.bonus,
  (value) => {
    form.use_bonus = value < 0;
  },
  {
    once: true,
  },
);

const isSubmitDisabled = computed(() => {
  if (appStore.timetable.event.is_send_tickets_by_sms && (!form.sendTicketsBySms || !form.agreement)) {
    return true;
  }

  return !form.agreement;
});

const bonusSwitchDisabled = ref(false);
const paymentType = ref(null);

/**
 * @param {Number} id
 * @param {Number} index
 * @param {Event} event
 */
function setTicketFullName(id, index, event) {
  if (!form.ticketsData[id]) {
    form.ticketsData[id] = {};
  }
  form.ticketsData[id][index] = { ...form.ticketsData[id]?.[index], full_name: event.target.value };
}

/**
 * @param {Number} id
 * @param {Number} index
 * @param {Event} event
 */
function setTicketDocumentNumber(id, index, event) {
  if (!form.ticketsData[id]) {
    form.ticketsData[id] = {};
  }
  form.ticketsData[id][index] = { ...form.ticketsData[id]?.[index], document_number: event.target.value };
}

function selectPaymentType({ value }) {
  const selectedPaymentType = String(value);
  if (selectedPaymentType.startsWith('9') && selectedPaymentType.length > 1) {
    form.payment_type = cloudPaymentsId;
    cartStore.addBankFee(cloudPaymentsId);
    return;
  }

  form.payment_type = value;
  cartStore.addBankFee(value).then((data) => {
    cartStore.$patch(data.cart);
  });
}

function createOrder() {
  form
    .post(route('front.orders.store'))
    .then(({ data }) => {
      window.location.href = data.redirect;
    })
    .catch(() => {});
}

function addPromocode(data) {
  cartStore.$patch(data.cart);
}

function addCertificate(data) {
  if (userStore.user) {
    userStore.user = data.user;
  }

  form.use_bonus = true;
  cartStore.$patch(data.cart);
}

function toggleBonuses(value) {
  bonusSwitchDisabled.value = true;
  if (value) {
    axios
      .put(route('front.cart.add-bonus'))
      .then(({ data }) => {
        cartStore.$patch(data.cart);
      })
      .finally(() => {
        bonusSwitchDisabled.value = false;
      });
  } else {
    axios
      .delete(route('front.cart.remove-bonus'))
      .then(({ data }) => {
        cartStore.$patch(data.cart);
      })
      .finally(() => {
        bonusSwitchDisabled.value = false;
      });
  }
}
</script>

<style scoped lang="scss">
a {
  @apply text-primary-500 no-underline hover:underline;
}
</style>
