<template>
  <Dialog
    v-model:visible="showModal"
    modal
    :block-scroll="true"
    @close="$emit('hide')"
  >
    <template #container>
      <div class="md:min-w-200 md:p-18 relative justify-center bg-white px-6 py-12 max-md:h-screen max-md:w-full">
        <button
          type="button"
          class="absolute right-6 top-6 size-6"
          @click="$emit('hide')"
        >
          <XCloseSvg class="size-6" />
        </button>
        <form
          class="flex flex-col justify-center gap-4"
          @submit.prevent="processRegistration"
        >
          <h1 class="text-center">
            {{ i18n.trans('Регистрация') }}
          </h1>
          <p class="text-center text-base">
            {{ i18n.trans('Уже есть аккаунт?') }}
            <a
              href="#"
              class="link text-sm font-bold"
              role="button"
              @click="$emit('show-login-modal')"
            >
              {{ i18n.trans('Войти') }}
            </a>
          </p>
          <div>
            <input
              v-model="form.full_name"
              type="text"
              :placeholder="i18n.trans('Имя')"
            />
            <small
              v-if="form.errors.full_name"
              class="text-danger text-sm"
            >
              {{ form.errors.full_name }}
            </small>
          </div>
          <div>
            <InputPhone v-model="form.phone" />
            <small
              v-if="form.errors.phone"
              class="text-danger text-sm"
            >
              {{ form.errors.phone }}
            </small>
          </div>
          <div>
            <input
              v-model="form.email"
              type="email"
              placeholder="E-mail"
              autocomplete="new-email"
            />
            <small
              v-if="form.errors.email"
              class="text-danger text-sm"
            >
              {{ form.errors.email }}
            </small>
          </div>
          <div>
            <input
              v-model="form.password"
              type="password"
              :placeholder="i18n.trans('Пароль')"
              autocomplete="new-password"
            />
            <small
              v-if="form.errors.password"
              class="text-danger text-sm"
            >
              {{ form.errors.password }}
            </small>
          </div>

          <PrimaryButton
            type="submit"
            class="mt-8"
          >
            {{ i18n.trans('Зарегистрироваться') }}
          </PrimaryButton>

          <SocialAuthButtons />
        </form>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import InputPhone from '@/Front/Components/InputPhone.vue';
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import SocialAuthButtons from '@/Front/Modals/SocialAuthButtons.vue';
import XCloseSvg from 'images/front/icons/x-close.svg';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, watch } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['hide', 'show-login-modal']);
const toast = useToast();
const form = useForm({
  full_name: null,
  phone: null,
  email: null,
  password: null,
});

const showModal = ref(false);
watch(
  () => props.show,
  (value) => {
    showModal.value = value;
  },
  { immediate: true },
);

const i18n = useTranslations();

function processRegistration() {
  form
    .post(route('front.register'))
    .then(({ data }) => {
      toast.add({
        severity: 'success',
        summary: i18n.trans('Успешно!'),
        detail: data.message,
        life: 3000,
      });

      emits('hide');
      window.location.reload();
    })
    .catch(() => {});
}
</script>
