<template>
  <ConfirmDialog :draggable="false" />
  <DynamicDialog />
  <Toast position="bottom-center" />
  <Toast
    group="cart"
    position="bottom-center"
  >
    <template #message="slotProps">
      <div class="flex w-full items-center gap-4 max-md:flex-col">
        <h3
          class="w-full text-lg font-bold"
          v-text="slotProps.message.summary"
        />
        <PrimaryButton
          type="link"
          class="min-w-fit"
          :href="route('front.orders.create')"
          size="small"
        >
          {{ i18n.trans('Оформить заказ') }}
        </PrimaryButton>
      </div>
    </template>
  </Toast>
  <Toast
    group="news"
    position="top-center"
    @close="closeNews"
  >
    <template #message="{ message }">
      <div class="w-full">
        <span class="block text-lg font-bold">{{ message.summary }}</span>
        <div
          class="block"
          v-html="message.detail"
        />
      </div>
    </template>
  </Toast>
  <Toast
    group="deadline"
    position="top-center"
    @close="closeDeadline"
  >
    <template #message="{ message }">
      <div class="w-full">
        <span class="block text-lg font-bold">{{ message.summary }}</span>
        <div
          class="block"
          v-html="message.detail"
        />
      </div>
    </template>
  </Toast>
  <GuestModals v-if="!userStore.user" />
  <Drawer
    v-model:visible="showNavBar"
    position="left"
    :block-scroll="true"
  >
    <template #container>
      <nav
        class="h-dvh w-full bg-white"
        style="min-height: -webkit-fill-available"
      >
        <div
          class="flex h-dvh flex-col overflow-y-auto"
          style="min-height: -webkit-fill-available"
        >
          <div class="flex w-full flex-col py-6 pl-8 pr-6">
            <div class="flex items-center justify-between">
              <a
                :href="route('front.mainpage.index')"
                class="flex items-center gap-3 hover:no-underline"
              >
                <img
                  :src="LogoImg"
                  alt="ZAKAZBILETOV.KZ"
                  title="ZAKAZBILETOV.KZ"
                />
              </a>
              <button
                type="button"
                class="flex size-10 items-center justify-center"
                @click="showNavBar = false"
              >
                <DoubleArrowSvg />
              </button>
            </div>
          </div>

          <div class="flex h-full flex-col gap-12 px-8 py-6">
            <button
              type="button"
              class="text-primary-600 flex h-12 w-full items-center rounded-lg bg-neutral-50 px-4 py-3 text-left"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="langMenu.toggle"
            >
              <div
                class="grow"
                v-text="currentLanguage"
              />
              <div>
                <ExpandSvg />
              </div>
            </button>
            <Menu
              id="langMenu"
              ref="langMenu"
              :model="langMenuItems"
              :popup="true"
            >
              <template #item="{ item, label }">
                <a
                  :href="item.url"
                  class="text-primary-600 hover:text-primary-700 relative flex cursor-pointer select-none items-center gap-3 overflow-hidden px-5 py-3 no-underline"
                >
                  {{ label }}
                </a>
              </template>
            </Menu>

            <ul class="space-y-6 text-xl font-bold leading-loose">
              <li>
                <a
                  :href="route('front.mainpage.index')"
                  class="flex items-center"
                >
                  <span class="grow">{{ i18n.trans('Главная') }}</span>
                </a>
              </li>
              <li>
                <Accordion value="0">
                  <AccordionPanel
                    v-for="menuItem in menuItems"
                    :key="`menu-item-${menuItem.id}`"
                    :value="menuItem.id"
                  >
                    <AccordionHeader>
                      <span class="grow text-left leading-none">
                        {{ menuItem.name }}
                      </span>
                    </AccordionHeader>
                    <AccordionContent>
                      <ul class="mt-4 space-y-4 text-sm font-normal leading-tight">
                        <li
                          v-for="childItem in menuItem.children"
                          :key="`menu-item-${childItem.id}`"
                        >
                          <a
                            :href="childItem.url"
                            v-text="childItem.name"
                          />
                        </li>
                      </ul>
                    </AccordionContent>
                  </AccordionPanel>
                </Accordion>
              </li>
            </ul>

            <PrimaryButton
              v-if="!userStore.user"
              class="mt-auto"
              type="button"
              @click="showLoginModal"
            >
              {{ i18n.trans('Войти в аккаунт') }}
            </PrimaryButton>
            <div
              v-else
              class="mt-auto"
            >
              <p class="pb-4 text-lg font-medium leading-7">
                {{ userStore.user.full_name }}
              </p>
              <ul class="profile flex flex-col gap-4 text-sm">
                <li class="stroke-secondary-500 flex items-center gap-2 font-bold text-white">
                  <OrdersSvg />
                  <a
                    class="text-secondary-500 uppercase no-underline hover:underline"
                    :href="route('front.users.orders')"
                  >
                    {{ i18n.trans('Мои заказы') }}
                  </a>
                </li>
                <li class="flex items-center gap-2 font-bold">
                  <ProfileSvg class="stroke-secondary-500" />
                  <a
                    class="text-secondary-500 uppercase no-underline hover:underline"
                    :href="route('front.users.profile')"
                  >
                    {{ i18n.trans('Личный кабинет') }}
                  </a>
                </li>
                <li class="flex items-center gap-2">
                  <LogoutSvg />
                  <a
                    class="font-bold no-underline hover:underline"
                    role="button"
                    @click="logout"
                  >
                    {{ i18n.trans('Выйти') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </template>
  </Drawer>
</template>

<script setup>
import PrimaryButton from '@/Front/Components/PrimaryButton.vue';
import useTranslations from '@/Front/Composables/useTranslations.js';
import GuestModals from '@/Front/Modals/GuestModals.vue';
import { useApp } from '@/Front/Stores/useApp.js';
import { useCart } from '@/Front/Stores/useCart.js';
import { useUser } from '@/Front/Stores/useUser.js';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import ConfirmDialog from 'primevue/confirmdialog';
import Drawer from 'primevue/drawer';
import DynamicDialog from 'primevue/dynamicdialog';
import Menu from 'primevue/menu';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import DoubleArrowSvg from '../../../images/front/icons/double_arrow_left.svg';
import ExpandSvg from '../../../images/front/icons/expand_more.svg';
import LogoutSvg from '../../../images/front/icons/logout.svg';
import ProfileSvg from '../../../images/front/icons/profile.svg';
import OrdersSvg from '../../../images/front/icons/ticket-outline.svg';
import LogoImg from '../../../images/front/images/logo.png';

dayjs.extend(isToday);
const appStore = useApp();
appStore.init();
const cartStore = useCart();
cartStore.init();
const userStore = useUser();
userStore.init();

const i18n = useTranslations();
i18n.setLocale(appStore.locale.current);

const showNavBar = ref(false);
const langMenu = ref();
const toast = useToast();
const menuItems = window.$app.menuItems;

function showLoginModal() {
  showNavBar.value = false;
  window.dispatchEvent(new CustomEvent('show-sms-login-modal'));
}

const langMenuItems = Object.values(window.$app.locale.list).map((locale) => ({
  label: locale.native,
  url: locale.url,
  popup: true,
}));

const currentLanguage = window.$app.locale.list[window.$app.locale.current]?.native;

function logout() {
  axios.delete(route('front.logout')).then(() => {
    location.reload();
  });
}

onMounted(() => {
  const readNews = window.localStorage?.getItem('news');
  let displayNews = true;
  if (readNews) {
    const news = JSON.parse(readNews);
    if (news.id === appStore.news.id && dayjs(news.date).isToday()) {
      displayNews = false;
    }
  }

  if (appStore.news && displayNews) {
    toast.add({
      severity: 'info',
      summary: appStore.news.name,
      detail: appStore.news.text,
      group: 'news',
    });
  }
});

function closeNews() {
  window.localStorage.setItem('news', JSON.stringify({ id: appStore.news?.id, date: dayjs().toDate() }));
}

function closeDeadline() {
  window.localStorage.setItem(
    'deadlineMessage',
    JSON.stringify({
      id: appStore.timetable?.id,
      date: dayjs().toDate(),
    }),
  );
}

window.addEventListener('show-sidebar', () => {
  showNavBar.value = true;
});

window.addEventListener('update-ticket-data', ({ detail }) => {
  console.log('update-ticket-data', detail);
});

onMounted(() => {
  if (appStore.toast.error) {
    toast.add({
      severity: 'error',
      summary: i18n.trans('Ошибка!'),
      detail: appStore.toast.error,
      life: 5000,
    });
  }

  if (appStore.toast.success) {
    toast.add({
      severity: 'success',
      summary: i18n.trans('Успех!'),
      detail: appStore.toast.success,
      life: 3000,
    });
  }
});

window.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    toast.add({
      severity: 'error',
      summary: 'Ошибка!',
      detail: error.response?.data?.message ?? i18n.trans('Ошибка при обработке запроса. Попробуйте позже!'),
      life: 3000,
    });

    throw error;
  },
);
</script>

<style lang="scss" scoped>
ul:not(.profile) {
  a {
    @apply text-primary-600 hover:text-primary-700 no-underline;
  }
}
</style>
